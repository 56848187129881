import React from 'react';
import _ from 'lodash';
import store from '../../../redux/store';
import { setCardBackData } from '../../../redux/actions/appBasicControls';
class BaseClass extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  componentWillUpdate(nextProps, nextState) {
    if (_.isEqual(nextProps, this.props) && _.isEqual(nextState, this.state)) return;
    // Get Data from State  
    const cardBackData = store.getState().appBasicControls.cardBackData;
    // Get Id which needs to be updated
    let findById = nextProps.root.dId;
    // Find it in current state
    // If No card back data return
    if (!cardBackData || !Array.isArray(cardBackData)) return;
    // Get index in current redux state
    let indexInState = cardBackData.findIndex(el => el.dId == findById);
    if (indexInState == -1) {
      return;
    };
    // Get the index if input which is changed
    const indexOfInput = nextProps.position;
    // Is that input has after processor?
    // this after process will update the data from state
    const afterProcessor = cardBackData[indexInState].inputs[indexOfInput].afterProcessor;
    const nextValue = afterProcessor ? afterProcessor(nextState.value) : nextState.value;
    // Get the current value in state
    const currentValue = cardBackData[indexInState].inputs[indexOfInput].value;
    // if current value and next value are same we are not going to update this
    // const isEqual = _.isEqualWith(nextValue, currentValue, (a, b) => {
    //   if (Array.isArray(a)) return _.isEmpty(_.xorWith(a, b, _.isEqual));
    // });
    const isEqual = _.isEqualWith(nextValue, currentValue, (a, b) => {
      if (Array.isArray(a) && Array.isArray(b)) {
        let result = true;
        if (a.length !== b.length) return false;
        a.map((item, index) => {
          if (!_.isEqual(item, b[index])) {
            result = false;
          }
        })
        return result;
      }
      return _.isEqual(a, b)
    });
    console.log('IsEqual: ', isEqual, nextValue, currentValue)
    if (isEqual || nextValue === undefined) {
      return;
    }
    // In other case we will add updatedAt to the data so we know when this module is updated
    console.log('SETTING UPDATED AT');
    console.log(nextValue);
    console.log(currentValue);
    // debugger;s
    cardBackData[indexInState].updatedAt = new Date().getTime();
    // Update the new value
    cardBackData[indexInState].inputs[indexOfInput].value = nextValue;
    // dispatch that change to the redux
    this.props.dispatch(setCardBackData(cardBackData));
    // if (onSaveDebounced) {
    //   onSaveDebounced.cancel();
    // }
    // onSaveDebounced = _.debounce(this.props.onUpdate, 1000);
    // cardBackData &&
    // onSaveDebounced(cardBackData);
  }

  componentDidMount() {
    this.setState({
      value: this.props.root.inputs[this.props.position].value,
    });
  }

  componentDidUpdate(prevProps) {
    let prev = prevProps.root.inputs[this.props.position].value;
    let next = this.props.root.inputs[this.props.position].value;
    if (prev != next) {
      this.setState({
        value: next,
      });
    }
  }

  render() {
    return false;
  }
}

export default BaseClass;
